body {
  background: #161a26 !important;
}
.form-log {
  position: relative;
}
.form-log img {
  position: absolute;
  bottom: 14px;
  font-size: larger;
  left: 18px;
  top: 10px;
}
.form-log label {
  color: white;
}
.btn-sign {
  border-radius: 50px;
  padding: 0 38px;
}

.log-body {
  background: url(images/log-bg.jpg) no-repeat;
  font-family: 'DM Sans', sans-serif;
  background-size: cover;
}
.form-group {
  margin-bottom: 1.2rem !important;
  font-family: 'DM Sans', sans-serif;
}
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 2.5rem;
  font-size: 1rem !important;
  line-height: 2 !important;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 1.45rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
a:hover {
  text-decoration: none;
}
.btn-primary {
  background: #55ff00;
  font-family: 'DM Sans', sans-serif;
  border: 0;
  line-height: 2;
  color: #000000;
}
.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active {
  background: #48d800 !important;
  color: #000000 !important;
}
.btn-outline-primary {
  border-color: #55ff00;
  line-height: 1.9;
  color: #000;
}
.btn-outline-primary:hover {
  background: #666;
  color: white;
  border-color: #666;
}
.enrollee-wrap h5 {
  /* color: #7dc242; */
  color: #55ff00;
  padding-bottom: 10px;
  font-size: 1.75rem;
  font-weight: bold;
}
.log-wrap {
  text-align: center;
  width: 400px;
}
.container-login {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100vh;
}
.login-form {
  background: white;
  padding: 50px;
  margin-top: 40px;
  border-radius: 10px;
  font-family: 'DM Sans', sans-serif;
}
.login-form button {
  width: 100%;
  background: #7dc242;
  border: 0;
  line-height: 2;
}
.login-form button:hover {
  background: #64a52d;
}
.container {
  max-width: 1250px;
}
.enrollees-list-search {
  /* width: 94%; */
  display: flex;
  flex-direction: column;
  padding-top: 45px;
}
.enrollees-list-search .MuiInputAdornment-root svg {
  cursor: pointer;
}
.container-inn-wrap .row {
  margin-left: 0;
  margin-right: 0;
  padding: 20px 15px;
}
.row-wrap {
  background: #161a26;
  margin: 50px 50px 0;
  border-radius: 1ch;
}
.enrollee-list .list-group-item {
  background: #1b1f2b;
  border-bottom: 1px solid #323642;
  margin-bottom: 0;
}
.enrollee-list a:last-child {
  border-bottom: 0;
}
.cursor-pointer {
  cursor: pointer;
}
.enrollee-list .list-group-item-action:hover {
  background: #1a1e2c;
}
.welcome-name {
  font-family: 'DM Sans', sans-serif;
  padding-top: 35px;
}
.welcome-name span {
  font-size: 25px;
  color: white;
}
.welcome-name p {
  font-size: 58px;
  color: #55ff00;
  font-weight: 600;
  line-height: normal;
}
.enrollee-list div img {
  float: left;
  width: 44px;
  border-radius: 50px;
}
.nm-id-wp {
  float: left;
  padding-left: 10px;
  color: white;
  font-size: 16px;
}
.display-none {
  display: none;
}
.nm-id-wp p {
  margin-bottom: 0;
  line-height: 25px;
}
.nm-id-wp span {
  font-size: small;
}
.pvc-pcy {
  padding-top: 10px !important;
  cursor: pointer;
}
.pvc-pcy div {
  margin: 0 auto;
  font-size: 14px;
  color: #84878e;
}
.pvc-pcy div:hover {
  color: white;
}
.enrollee-popup .modal-dialog {
  max-width: 400px;
}
.enrollee-popup .modal-header {
  text-align: center;
  border-bottom: 0;
}
.enr-list-pop-tb {
  text-align: center;
}
.enr-list-pop-tb img {
  width: 60px;
  height: 60px;
  overflow: hidden;
  border-radius: 50%;
  border-radius: 50px;
}
.enrollee-popup .modal-footer button {
  width: 47%;
}
.enrollee-popup .modal-footer {
  border-top: 0;
  justify-content: center;
}

.enrollee-image {
  width: 46px;
  height: 46px;
  float: left;
  overflow: hidden;
  border-radius: 50%;
}
.enrollee-image img {
  width: 100%;
}

.list-group-item-action {
  width: auto;
  /* margin-right: 20px; */
}
.enrollees-list-scroll {
  width: 100% !important;
}
.enrollees-list-scroll div:nth-child(3) {
  z-index: 1;
}
.enrollees-list-scroll > div:nth-child(3) div {
  background-color: rgba(255, 255, 255, 0.2) !important;
}

.modal-title {
  font-size: 18px;
  color: #212529;
}

.cs-form-group {
  display: block;
  margin-bottom: 15px;
}

.cs-form-group input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.cs-form-group label {
  position: relative;
  cursor: pointer;
  color: white;
  font-size: smaller;
  font-family: 'DM Sans', sans-serif;
}

.cs-form-group label:before {
  content: '';
  -webkit-appearance: none;
  background-color: white;

  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 10px;
}

.fgt-ps-btn {
  float: right;
  color: white;
  font-size: 14px;
  padding-top: 7px;
}
.fgt-ps-btn:hover {
  color: #7dc242;
}
.cs-form-group input:checked + label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 7px;
  width: 7px;
  height: 14px;
  border: solid #000000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.login,
.image {
  min-height: 100vh;
}

.bg-image {
  background: url(images/log-bg.jpg) no-repeat;
  background-size: cover;
  background-position: center;
}

.login-heading {
  font-weight: 300;
}

.btn-login {
  font-size: 0.9rem;
  letter-spacing: 0.05rem;
  padding: 0.75rem 1rem;
  border-radius: 2rem;
}

.form-label-group {
  position: relative;
  margin-bottom: 1rem;
}

.form-label-group > input,
.form-label-group > label {
  padding: var(--input-padding-y) var(--input-padding-x);
  height: auto;
  border-radius: 2rem;
}

.form-label-group > label {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  margin-bottom: 0;
  /* Override default `<label>` margin */
  line-height: 1.5;
  color: #495057;
  cursor: text;
  /* Match the input under the label */
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.1s ease-in-out;
}

.form-label-group input::-webkit-input-placeholder {
  color: transparent;
}

.form-label-group input:-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-ms-input-placeholder {
  color: transparent;
}

.form-label-group input::-moz-placeholder {
  color: transparent;
}

.form-label-group input::placeholder {
  color: transparent;
}

.form-label-group input:not(:placeholder-shown) {
  padding-top: calc(var(--input-padding-y) + var(--input-padding-y) * (2 / 3));
  padding-bottom: calc(var(--input-padding-y) / 3);
}

.form-label-group input:not(:placeholder-shown) ~ label {
  padding-top: calc(var(--input-padding-y) / 3);
  padding-bottom: calc(var(--input-padding-y) / 3);
  font-size: 12px;
  color: #777;
}
.main-video {
  position: absolute;
  top: 7vh;
}

.small_thumb {
  position: absolute;
  bottom: 35px;
  right: 50px;
  width: 210px;
}
.small_thumb video {
  border-radius: 10px;
}
.main-container {
  height: 86vh;
  overflow: hidden;
  border-radius: 12px;
}
.main-container video {
  max-height: initial !important;
}
.bottom-controls {
  bottom: 10vh !important;
}
.top-controls {
  position: absolute;
  right: 25px;
  top: 10px;
  z-index: 11;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:focus,
.btn-primary.focus {
  background-color: #48d800;
  border-color: #48d800;
  box-shadow: none;
}
.logout-btn {
  padding: 14px !important;
}
.logout-btn .MuiSvgIcon-root {
  font-size: 19px;
}

.login .alert {
  padding: 0.5rem 1.25rem;
  border-radius: 25px;
}
/* Fallback for Edge
  -------------------------------------------------- */
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 667px) {
  .log-wrap {
    text-align: center;
    width: 100%;
  }
  .login-form {
    padding: 35px;
  }
  .welcome-wrap {
    padding-top: 20px;
  }
  .welcome-name {
    /* float: left; */
    padding-top: 0;
  }
  .welcome-name span {
    font-size: 16px;
  }
  .welcome-name p {
    font-size: 18px;
  }
  .container-inn-wrap .row {
    padding: 0;
  }
}
.loadingSpinner {
  margin-left: '1em';
}

.error-pop .MuiPaper-root {
  background-color: #fff;
  text-align: center;
}
.error-pop .MuiPaper-root h2,
.error-pop .MuiTypography-colorTextSecondary {
  color: #212529;
}

.error-pop .MuiDialogActions-root {
  display: initial !important;
  padding-bottom: 15px !important;
}
.error-pop .MuiDialogActions-root .btn {
  min-width: 150px;
}
.page-loader {
  width: 100%;
  height: 100%;
  position: fixed;
  background: rgba(0, 0, 0, 0.65);
  top: 0;
  z-index: 11;
}
.page-loader div {
  /*z-index: 111111;*/
  position: fixed;
  top: 49%;
  left: 49%;
  z-index: 999;
}

.mutevideo-main {
  width: 18%;
  text-align: center;
  max-width: 175px;
  margin: 0 auto;
  z-index: 11;
}
.mutevideo-main img {
  width: 30%;
  display: block;
  margin: 0 auto 4px;
}
.mutevideo-main span {
  font-size: 15px;
  padding-top: 5px;
  overflow-wrap: normal;
  word-wrap: break-word;
}
.small_thumb .mutevideo-main {
  width: 100%;
}
.enrollees-list-search .MuiFilledInput-input {
  padding: 15px 12px 15px;
}
.enrollees-list-search .MuiFilledInput-underline:after {
  border-color: #55ff00;
}
.MuiCircularProgress-colorPrimary {
  color: #55ff00 !important;
  /* margin: 0 auto; */
  margin-left: 40%;
  margin-top: 10px;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
/* @media only screen and (min-width: 600px) {...}

/* Medium devices (landscape tablets, 768px and up) */
/* @media only screen and (min-width: 768px) {...} */

/* Large devices (laptops/desktops, 992px and up) */
/* @media only screen and (min-width: 992px) {...} */

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {...} */
/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */

@media only screen and (min-width: 320px) {
}

/* Extra Small Devices, Phones */

@media only screen and (min-width: 480px) {
  .voicecall {
    display: none !important;
  }
}

/* Small Devices, Tablets */

@media only screen and (min-width: 768px) {
  .enrollees-list-search {
    /* margin-right: 20px; */
    display: flex;
    flex-direction: column;
  }
}

/* Medium Devices, Desktops */

@media only screen and (min-width: 992px) {
}

@media only screen and (min-width: 1024px) {
}

@media only screen and (min-width: 1025px) {
}

@media only screen and (min-width: 1100px) {
}

/* Large Devices, Wide Screens */

@media only screen and (min-width: 1200px) {
  .new_list li {
    width: 100%;
  }
}

/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 1024px) {
  .full-screen {
    display: none !important;
  }
  .bg-image {
    background-position: left;
  }
  .main-container {
    height: 100vh;
    border-radius: 0;
  }
  .main-video {
    top: 0;
    padding: 0;
  }
  .main-video .container {
    padding: 0;
  }
  .enrollees-list-scroll > div {
    width: 100%;
  }
}

/* Medium Devices, Desktops */

@media only screen and (max-width: 992px) {
  .Enroll_edit_main .fieldmain {
    margin-bottom: 40px;
  }
}

/* Small Devices, Tablets */

@media only screen and (max-width: 768px) {
  .bg-image,
  .login {
    min-height: 50vh;
  }
  .small_thumb {
    bottom: 5%;
  }
  .fieldmain {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 767px) {
  .login {
    height: 100vh;
  }
  .list-group-item-action {
    margin-right: 0;
  }
  .Enroll_edit_main h6 {
    margin-bottom: 30px;
  }
}

@media only screen and (max-width: 640px) {
  .ChatWindow {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .minh-305 {
    min-height: 305px;
  }
  .chatHeading {
    padding-top: 10px !important;
  }
}

/* Extra Small Devices, Phones */

@media only screen and (max-width: 480px) {
  .thumbnail-video {
    width: 120px !important;
  }
  .small_thumb {
    width: 120px;
    right: 30px;
    bottom: 16%;
  }
  .react-datepicker-popper {
    left: -35px !important;
  }
  .selected_enrollee {
    padding-top: 20px;
  }
}

/* Custom, iPhone Retina */

@media only screen and (max-width: 375px) {
  .main-video {
    padding: 0;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 2) {
}

@media only screen and (device-width: 375px) and (device-height: 812px) and (-webkit-device-pixel-ratio: 3) {
  .small_thumb {
    bottom: 22%;
  }
}
.pvc-pcy a {
  width: 100%;
  text-align: center;
  color: white;
}

.w100 {
  width: 100%;
}
.mx-15 {
  margin-left: -15px !important;
  margin-right: -15px !important;
}
.minwidth150 {
  min-width: 150px;
}
.new_list {
  padding: 30px 0;
}

.new_list li {
  background-color: #55ff00;
  padding: 15px;
  display: block;
  color: #000;
  border-radius: 5px;
  align-items: center;
  list-style: none;
  margin-bottom: 30px;
  text-align: center;
  height: 180px;
  font-size: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.new_list li:hover {
  background: #48d800;
}
.new_list li span {
  display: flex;
  width: 100%;
  text-align: center;
  height: 80px;
  align-items: center;
  justify-content: center;
}
.new_list li img {
  max-height: 50px;
  max-width: 50px;
}
.Enroll_edit_main {
  padding: 30px 15px 30px 15px;
  background-color: #1b1f2b;
}
.Enroll_edit_main .MuiFormLabel-root {
  font-size: 13px;
  color: #f1f8ff;
  opacity: 0.7;
}
.Enroll_edit_main .MuiFormLabel-root.Mui-focused {
  color: #f1f8ff;
}
.Enroll_edit_main fieldset {
  margin-bottom: 25px;
  width: 100%;
}
.Enroll_edit_main form {
  margin-bottom: 40px;
}
.Enroll_edit_main .MuiFormLabel-root.label_disabled {
  opacity: 0.3;
}

.Enroll_edit_main .MuiRadio-colorSecondary.Mui-checked {
  color: #55ff00;
}
.Enroll_edit_main .MuiInput-underline:after {
  border-color: #55ff00;
}
.Enroll_edit_main .MuiIconButton-colorSecondary:hover,
.Enroll_edit_main .MuiRadio-colorSecondary.Mui-checked:hover {
  background-color: rgb(125 194 66 / 7%);
}

.logged_user {
  float: right;
  padding-right: 15px;
  display: flex;
  align-items: center;
  font-size: 16px;
}
.logged_user span.logged_img {
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 10px;
}
.logged_user span.logged_img img {
  width: 100%;
}

.pointer {
  cursor: pointer;
}

/*Calendar*/
.react-datepicker-wrapper {
  width: 100%;
}
.datetimepicket {
  background: transparent;
  background: url('images/calendar.svg') no-repeat;
  background-position: right 4px top 5px;
  background-size: 17px;
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.7);
  color: #fff;
  padding: 5px 0;
  width: 100%;
}
.datetimepicket:focus-visible {
  outline: 0;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-,
.react-datepicker__day--today-- .react-datepicker-time__input {
  background-color: #55ff00;
  color: #000;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--today--:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover,
.react-datepicker__time-container,
.react-datepicker__time,
.react-datepicker__time-box,
ul.react-datepicker__time-list,
li.react-datepicker__time-list-item--selected:hover,
.react-datepicker__day--keyboard-selected {
  background-color: #48d800;
}

.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item--selected
  react-datepicker-time__input {
  background-color: #64a52d;
}
.react-datepicker__time-container
  .react-datepicker__time
  .react-datepicker__time-box
  ul.react-datepicker__time-list
  li.react-datepicker__time-list-item {
  display: flex;
  align-items: center;
}

.react-datepicker-time__caption {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}
.enrollee-list .selected {
  background: url('images/right_arrow.png') no-repeat right 10px center !important;
  background-color: #2b2f3a !important;
  background-size: 12px !important;
}
.locationIcn {
  background: #ffffe3;
  width: 30px !important;
  height: 30px !important;
  padding: 3px;
  border-radius: 50%;
  color: #161a26;
  cursor: pointer;
}
.locationIcnOn {
  background: #7dc242;
  width: 30px !important;
  height: 30px !important;
  padding: 3px;
  border-radius: 50%;
  color: #161a26;
  cursor: pointer;
}
.chatHeading {
  align-items: center;
}
.ChatWindow {
  height: 85%;
  flex-direction: column;
  border: #2b2f3a solid 1px;
  position: relative;
  min-height: 400px;
}
.ChatWindow .page-loader {
  display: flex;
  flex-direction: column;
  position: absolute;
  background: none !important;
}
.ChatWindow .page-loader div {
  position: absolute;
  top: calc(50% - 30px);
  left: calc(50% - 30px);
}
.chatcontainer {
  overflow: auto;
}
.bd-highlight {
  background-color: #2b2f3a;
}
.chatInput {
  flex: 1;
}
.chat {
  margin-bottom: 15px;
}
.chat .chatperson,
.chat .chattime {
  font-size: 13px;
  color: #256f00;
  white-space: nowrap;
}

.chat1 {
  background-color: #2d3343;
  border-radius: 0 15px 15px 15px;
  width: 100%;
  max-width: 400px;
}
.chat1 .chatperson,
.chat1 .chattime {
  font-size: 13px;
  color: #8e96ac;
  white-space: nowrap;
}
.chat1 .chatmsg {
  font-size: 15px;
  color: #ffffff;
}
.chat2 {
  background-color: #55ff00;
  border-radius: 15px 0px 15px 15px;
  width: 100%;
  max-width: 400px;
}
.chatmsg {
  font-size: 15px;
  color: #000000;
}
.location_map {
  position: absolute;
  bottom: 0;
  height: 50%;
  width: 100%;
}
.location_heading {
  text-align: center;
  position: absolute;
  top: -54px;
  width: 100%;
  padding: 15px;
  background-color: #2d3343;
}
.location_close {
  position: absolute;
  right: 10px;
  top: 15px;
  cursor: pointer;
}
.location_pop {
  margin: 0 15px 15px 15px;
}
.location_pop li {
  background-color: #55ff00;
  padding: 25px 15px;
  display: block;
  color: #000;
  border-radius: 5px;
  align-items: center;
  cursor: pointer;
  list-style: none;
  margin-bottom: 15px;
}
.leftsidebar {
  padding-right: 20px;
}
.btn-primary.disabled,
.btn-primary:disabled {
  background-color: #48d800;
  border-color: #48d800;
  color: #000000;
}
.pop-form .MuiFormLabel-root,
.pop-form .MuiSelect-root {
  color: #161a26;
}
.pop-form .MuiInput-underline:before {
  border-bottom: #ced4da solid 1px !important;
}
.pop-form .MuiInput-underline:after {
  border-color: #7dc242;
}
/* .pop-form .MuiInputBase-root:hover{border-bottom: #7dc242 solid 1px;} */
.MuiCheckbox-colorSecondary.Mui-checked,
.MuiFormLabel-root.Mui-focused,
.pop-form .MuiSelect-icon {
  color: #55ff00 !important;
}
.pop-textarea .MuiInputBase-multiline {
  border: #ccc solid 1px !important ;
  color: #161a26;
  border-radius: 15px;
}
.pop-textarea .MuiInputBase-multiline.Mui-focused {
  border: #000 solid 1px !important;
}
.pop-textarea .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border: none !important;
}
.MuiMenu-paper {
  max-height: 200px !important;
}
.dropdown-container {
  color: #212529;
}
.text-dark {
  color: #212529;
}
.text-center {
  text-align: center;
}
.rmsc {
  --rmsc-main: #7dc242 !important;
}

.rmsc .options {
  max-height: 170px !important;
}
.rmsc .options * {
  margin: 0;
}

.dropdown-content {
  bottom: 110% !important;
  top: auto !important;
}
.full-screen,
.locationtoggle {
  background-color: rgba(0, 0, 0, 0.5) !important;
  margin-left: 10px !important;
}
.locationtoggleOn {
  background-color: #7dc242 !important;
  margin-left: 10px !important;
}
.full-screen:focus,
.locationtoggle:focus,
.locationtoggleOn:focus {
  outline: 0 !important;
}
.modal {
  /* z-index: 1111111111111 !important;*/
}
.voicemail {
  float: right;
  cursor: pointer;
  margin-right: 20px;
}
.voicemail img {
  width: 28px;
}
.calenderTopIcon {
  float: right;
  cursor: pointer;
  margin-right: 20px;
  margin-top: 2px;
}

.voicemail_main {
  padding: 20px 15px 20px 15px;
  background-color: #1b1f2b;
  margin-bottom: 15px;
}
.voice_date,
.voice_user span {
  font-size: 12px;
  /*color: #747b8f;*/
}
.voice_user {
  font-size: 15px;
}
.voice_player {
  padding: 15px;
  background-color: #222538;
  border-radius: 6px 6px 0 0;
}
.voice_player > div {
  background-color: transparent !important;
}
.voice_player .MuiGrid-item {
  display: flex;
  align-items: center;
}
.voice_player .MuiGrid-item svg {
  /* color: #7dc242;*/
  color: #55ff00;
}
.voice_player .MuiGrid-item .MuiSlider-thumb {
  /*background-color: #7dc242;*/
  background-color: #55ff00;
}
.player_options {
  background-color: #222538;
  padding: 5px;
  border-top: #000000 solid 1px;
  border-radius: 0 0 6px 6px;
}
.player_options button {
  font-size: 12px;
  font-weight: normal;
  text-transform: capitalize;
  /*color: #747b8f;*/
  line-height: 24px;
  padding: 3px;
}
.player_options button svg {
  width: 20px;
  color: #fff;
  margin-right: 3px;
}
.player_options button img {
  width: 16px;
  margin-right: 3px;
}
.player_options .MuiGrid-item {
  text-align: center;
}
.record_body {
  padding: 0 15px 30px;
  background-color: #212121;
}
.record_body ._2fG9h {
  padding: 0;
}
.record_body h2 {
  font-size: 20px;
}
.record_header {
  background-color: #212121;
}
.record_header .close {
  color: #fff;
}
.record_body ._1lB9c {
  text-align: center;
  background: transparent;
  display: block !important;
}
.record_body h2 {
  color: #7dc242;
}
.record_body ._3-aC9 {
  display: none !important;
}
.record_body ._1Yplu {
  text-align: center;
  margin-bottom: 0;
}
.record_body ._37kfa {
  background: #7dc242 !important;
  border-color: #7dc242 !important;
}
.record_body ._37kfa:hover {
  background: #538b23 !important;
  color: #fff !important;
}
.record_body ._2gd2_ {
  border-color: #7dc242 !important;
  color: #7dc242 !important;
  background-color: transparent !important ;
}
.record_body ._1YOWG {
  bottom: 90px !important;
}
.record_body ._1dpop {
  bottom: 40px;
}
.record_body ._1YOWG {
  width: 85%;
  text-align: center;
}
.record_icon {
  width: 75px;
  height: 75px;
  background: url('../css/images/recordIcon.png');
  border: #2b2f3a solid 7px;
  border-radius: 50%;
  background-size: 100%;
}
.recordstop_icon {
  width: 75px;
  height: 75px;
  background: url('../css/images/stopIcon.png');
  border: #2b2f3a solid 7px;
  border-radius: 50%;
  background-size: 100%;
}
.record_icon_second {
  width: 75px;
  height: 75px;
  background: url('../css/images/record-second.png');
  border: #2b2f3a solid 7px;
  border-radius: 50%;
  background-size: 100%;
}
.record_icon:hover,
.recordstop_icon:hover {
  border-color: #303a54;
}
.record-container button {
  margin: 0 10px;
}
.record-msg {
  background-color: #222538;
  padding: 30px;
  margin-bottom: 30px;
  margin-top: 20px;
}
.record-msg p {
  margin-bottom: 0;
}
.record-msg p.start {
  color: #747b8f;
}
.record-msg p.recording {
  color: #c23947;
}
.btn-cancel {
  min-width: 250px !important;
  background-color: #7dc242 !important;
}

.voicemail-select {
  /* min-width: 120px !important;
  margin-left: 10px !important;*/
  margin: 0 10px 0 10px !important;
}
.voicemail_outer {
  max-height: 550px;
  overflow-y: visible;
  overflow-x: hidden;
  position: relative;
}

.sent_voicemails .MuiGrid-item {
  text-align: right !important;
}
.new_voicemail {
  text-align: center !important;
}
.red {
  color: #c23947;
}

.voice_wrapper {
}

.voice_wrapper #voice-recording-body {
}
.voice-display-flex {
  display: flex;
  align-items: center;
  justify-content: center;
  /*margin-top: 10px;*/
}
.voice_wrapper .voice-date-picker {
  margin-top: 10px;
}
.voice_wrapper .voice-date-picker .datetimepicket {
  min-width: 200px;
}
.voice_wrapper .btn_wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}

.voice_wrapper .duration_section {
  /*bottom: 0px !important;*/
  display: flex;
  flex-direction: column;
  width: 100%;
}

.enrollee-popup .modal-content {
  border: none !important;
}

.voice_wrapper .audio_section {
  display: flex;
  justify-content: center;
}

/*::-webkit-scrollbar {
  width: 5px !important;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #888;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
}*/
.Schedule {
  margin-right: 10px;
  font-size: 20px;
}
.datetimepicker {
  margin-bottom: 20px;
}

.voice_wrapper .recorder_box_inner {
  min-height: 340px !important;
}

.voice_wrapper .recorder_box {
  padding: 0px !important;
}
.voice_wrapper .help {
  font-size: 12px;
}
.voice_wrapper .upload_btn,
.clear_btn {
  border: none !important;
  border-radius: 30px !important;
  color: black !important;
  background-color: white !important;
}
.voice_wrapper .upload_btn:hover:enabled,
.clear_btn:hover:enabled {
  border: none !important;
  background-color: #64a52d !important;
  color: white !important;
}

/* Appointment*/
.calenderWrap .MuiPickersDatePickerRoot-toolbar {
  display: none;
}
.calenderWrap .MuiPickersBasePicker-pickerView {
  width: 100% !important;
  max-width: 100%;
}
.calenderWrap .MuiPickersCalendarHeader-dayLabel {
  /* width: 75px !important;*/
  width: 100%;
  outline: none;
}

.calenderWrap .MuiPickersDay-day {
  margin: 5px auto;
  width: 45px;
  height: 45px;
}
.calenderWrap .MuiPickersStaticWrapper-staticWrapperRoot {
  background-color: #2c2f44;
  border-radius: 10px;
}
.calenderWrap .MuiPickersCalendarHeader-switchHeader {
  background-color: #222537;
  margin: 0px 0px 12px 0px;
  height: 75px;
  border-radius: 10px;
}
.calenderWrap .MuiPickersCalendarHeader-switchHeader .MuiSvgIcon-root {
  font-size: 2.5rem;
}
.calenderWrap .MuiPickersCalendarHeader-switchHeader .MuiTypography-body1 {
  font-size: 1.24rem;
  font-weight: 300;
}

.calenderWrap .MuiPickersCalendarHeader-switchHeader .MuiIconButton-root {
  padding: 6px;
  margin: 0px 5px;
}

.calenderWrap .MuiPickersBasePicker-pickerViewLandscape {
  padding: 0;
}
.calenderWrap .MuiPickersCalendarHeader-iconButton {
  background-color: #222537 !important;
  outline: none;
}
.calenderWrap .MuiPickersCalendarHeader-iconButton:hover {
  background-color: #292c3e !important;
}
.calenderWrap .MuiPickersCalendarHeader-transitionContainer {
  height: 30px;
}
.calenderWrap .MuiTypography-caption {
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
}
.calenderWrap .MuiPickersCalendarHeader-daysHeader {
  max-height: 26px !important;
  display: flex;
  justify-content: space-around !important;
}
.calenderWrap .MuiPickersCalendar-week {
  justify-content: space-around !important;
}
.calenderWrap .MuiPickersDay-daySelected {
  background-color: #3b4060 !important;
  width: 45px;
  height: 45px;
  outline: none;
}
.calenderWrap .MuiPickersDay-current {
  background-color: #7a2400 !important;
  width: 45px;
  height: 45px;
  outline: none;
  opacity: 1;
  color: #fff !important;
}

.calenderWrap .MuiPickersDay-daySelected:hover {
  background-color: #454a6a;
}
.calenderWrap .MuiTypography-body2 {
  font-size: 1.15rem !important;
  font-weight: 300;
}
.calenderWrap .MuiPickersCalendar-transitionContainer {
  /*min-height: 325px !important;*/
  min-height: 300px !important;
}

.calenderWrap .MuiBadge-anchorOriginTopRightRectangle {
  top: 48px;
  /* right: 45px;*/
  color: transparent;
  text-shadow: 0 0 0 #8afa4d;
  font-size: 0.55rem;
  right: auto;
  padding: 0.33rem;
}

.searchBar {
  background: #dcdcdc;
  border-radius: 10px;
  padding: 10px;
  margin-left: auto;
  width: 85%;
  margin-bottom: 40px;
}
.searchIconColor {
  color: #9b9b9b !important;
  font-size: 2.15rem !important;
}
.searchBar .MuiInputBase-root {
  color: #000 !important;
}
.searchBar .MuiFormControl-root {
  width: 90% !important;
}
.searchBar .MuiInput-underline::before {
  border-bottom: 0px !important;
}
.searchBar .MuiInput-underline::after {
  border-bottom: 0px !important;
}
.searchBar .MuiInputBase-input {
  font-size: 1.25rem !important;
}
.message {
  color: #a7a8aa;
  font-size: 24px;
}
.appointmentWrap {
}
.appointmentTop {
  padding: 0px;
  background-color: #222538;
  border-radius: 8px 8px 0 0;
  color: #a2a3a8;
}
.appointmentTop .dateTime {
  border-right: 1px solid #353a56;
  color: #a2a3a8;
  width: 130px;
}
.appointmentTop .dateTime h5 {
  font-size: 15px !important;
  font-weight: bold;
  color: #8afa4d;
  padding: 0 0 6px 0;
  margin: 0;
}
.appointmentTop .dateTime h4 {
  font-size: 17px !important;
  font-weight: 400;
  color: #fff;
  padding: 0 0 5px 0;
  margin: 0;
}
.appointmentTop p {
  font-size: 17px !important;
}
.appointmentTop .dateTimeRight {
  width: 75%;
}

.appointmentBtm {
  background-color: #222538;
  padding: 5px;
  border-top: #000000 solid 1px;
  border-radius: 0 0 8px 8px;
}
.appointmentBtm .btn-primary {
  background: none !important;
  color: #fff !important;
  font-size: 13px;
  font-weight: bold;
}
/*Date Time picker*/
.dateTimeField .MuiFormControl-root {
  border-radius: 0;
  background-color: #151727;
  border: 1px solid #454760;
  padding: 3px 0px 3px 10px;
  color: #fff;
  max-width: 230px;
}
.dateTimeField .MuiInput-underline::before,
.dateTimeField .MuiInput-underline::after,
.dateTimeField .MuiInput-underline:hover:not(.Mui-disabled)::before {
  border: 0px;
  transition: none;
}
.dateTimeField .MuiTouchRipple-root {
  transition: none;
}
.dateTimeField .MuiIconButton-root {
  transition: none;
}

/*Calender ---------------------*/
.MuiPickersDay-daySelected {
  background-color: #7a2400 !important;
}
.MuiButton-textPrimary {
  background-color: #62ff29 !important;
  color: #000 !important;
}

/*----------------------------*/

button:focus {
  outline: none;
}
.dateTimeWrap .css-1s2u09g-control {
  border: 1px solid #454760;
  background-color: #151727;
}
.dateTimeWrap .css-1pahdxg-control {
  border: 1px solid #454760;
  background-color: #151727;
}
.dateTimeWrap .css-319lph-ValueContainer {
  border-radius: 0;
  background-color: #151727;
  border: 0px;
  color: #fff;
}
.dateTimeWrap .css-tlfecz-indicatorContainer,
.dateTimeWrap .css-1hb7zxy-IndicatorsContainer {
  background-color: #151727;
}

.dateTimeWrap .css-14el2xx-placeholder,
.dateTimeWrap .css-6j8wv5-Input {
  background: none !important;
}
.dateTimeWrap .css-1okebmr-indicatorSeparator {
  width: 0 !important;
}
.dateTimeWrap .css-b62m3t-container {
  color: #000 !important;
}
.dateTimeWrap .css-qc6sy-singleValue,
.dateTimeWrap .css-1pndypt-Input {
  color: #fff !important;
}
.dateTimeWrap .css-vwja0k {
  color: #fff !important;
}
.error {
  color: #ff2e2e;
  font-size: 0.875rem;
}

/*Modal Popup*/

.modal.calenderPopup {
  top: 50px;
}
.calenderPopup .modal-content {
  background: #141729;
  border-radius: 0.6rem;
}
.calenderPopup .modal-footer .btn-primary {
  background-color: #62ff29;
  border-radius: 15px;
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  min-width: 130px;
  font-weight: 400;
}
.calenderPopup .modal-backdrop.show {
  opacity: 0.15;
}
.calenderPopup .modal-backdrop {
  background-color: #fff;
}
.calenderPopup .modal-header {
  border-bottom: 1px solid #141729;
  padding: 0px;
}
.calenderPopup .modal-header .close {
  background: #fff;
  opacity: 1;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  font-size: 35px;
  font-weight: 300;
  padding: 1px 0px 0px 0px;
  margin: -20px -20px 0 auto;
}
.calenderPopup .modal-header .close span {
  display: block;
  margin-top: -8px;
}
.calenderPopup .modal-footer {
  border-top: 0px;
}
.calenderPopup .modal-body {
  padding: 0 20px;
}

.bdrBtm {
  border-bottom: 1px solid #353a56;
}
.calenderPopup .modal-body .dateTimeWrap {
  font-size: 17px;
}
.calenderPopup .modal-body p {
  font-size: 16px;
  color: #99999e;
}
.fnt14 {
  font-size: 14px;
}

.calenderPopup .modal-body .dateTimeWrap .searchDiv {
  background-color: #151727;
  border: 1px solid #454760;
  padding: 0px;
  display: flex;
}
.calenderPopup .modal-body .dateTimeWrap .searchDiv .form-control {
  border: 0px solid #454760;
  padding: 10px;
  outline: none;
}
.calenderPopup .modal-body .dateTimeWrap .searchDiv .form-control:focus {
  box-shadow: none;
}
.calenderPopup .modal-body .dateTimeWrap .searchDiv .MuiSvgIcon-root {
  margin-top: 6px;
  margin-right: 3px;
  cursor: pointer;
}
.calenderPopup .modal-body .dateTimeWrap .searchDiv .searchPopup {
  position: absolute;
  z-index: 99;
  top: 42px;
  background: #232537;
  font-size: 14px;
  padding: 10px;
  width: 94.5%;
  border: 1px solid #3f4158;
  line-height: 28px;
  max-height: 300px;
  overflow-y: scroll;
}

.calenderPopup .form-control {
  border-radius: 0;
  background-color: #151727;
  border: 1px solid #454760;
  padding: 10px;
  color: #fff;
}

@media only screen and (max-width: 375px) {
  .voice_wrapper .react-datepicker-popper {
    left: -110px !important;
  }
  .voicemail_main {
    padding-left: 0px;
    padding-right: 0px;
  }
  /*.voicemail-new .new_voicemail div span {display: none;}*/
  .button-reponsive {
    display: block !important;
  }
  .voicemail-new {
    display: flex;
    justify-content: space-between;
  }
}

@media only screen and (max-width: 1200px) {
  .searchBar {
    margin-top: 40px;
    width: 100%;
  }
}
@media only screen and (max-width: 1440px) {
  .searchBar .MuiInputBase-input {
    font-size: 1.05rem !important;
  }
}
@media screen and (min-width: 1196px) and (max-width: 1626px) {
  .appointmentTop .dateTimeRight {
    width: 60%;
  }
}
.TranscriptionIcon {
  margin-top: 4px;
  height: 15px;
  margin-bottom: 4px;
}

/*.voice_wrapper .upload_btn:hover:enabled {border: none !important; background-color: #7dc242 !important; color: white !important;}
.voice_wrapper .upload_btn{border: none !important;}*/

.voicemail_outer .page-loader div,
.voicemail_outer .page-loader {
  position: absolute;
}

.voicemail-wrap {
  position: relative !important;
  min-height: 500px !important;
}

.voicemail-wrap .page-loader,
.voicemail-wrap .page-loader div {
  position: absolute !important;
}

.voicemail_outer button:focus,
.modal-header button {
  outline: 0px;
}
.voicemail-heading h5 {
  color: #55ff00;
}
.voicemail-wrap .page-loader {
  background: none;
}
.voicemail-wrap .btn-primary {
  background: #55ff00 !important;
}
.voicemail-wrap .btn-primary:hover {
  background: #48d800 !important;
}
.searchvoice {
  padding: 8px !important;
  border-radius: 3px;
  margin-bottom: 5px;
}
.searchvoice::before {
  border-bottom: 0 !important;
}
.searchvoice .MuiFilledInput-input {
  padding: 0;
  font-size: 14px;
}
.voicemsgdetails {
  justify-content: space-between;
}

.stop_pause_btn {
  display: flex;
  justify-content: center;
}

/*Pagination Style*/
.pagination {
  padding: 10px;
}
.pagination .page-link {
  background-color: #1b1f2b;
  border: 1px solid #363a46;
  color: #fff;
}
.pagination .page-link:hover {
  background-color: #24262e;
  border: 1px solid #363a46;
  color: #fff;
}
.pagination .page-item.active .page-link {
  background-color: #606583;
  border: 1px solid #363a46;
}

.pagination .page-item.disabled .page-link {
  background-color: #1b1f2b;
  color: #757783;
  border: 1px solid #363a46;
}
