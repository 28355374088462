body {
  padding: 0;
  margin: 0;
}

.App {
  height: 50%;
  width: 100%;
  left: 0;
  position: fixed;
  bottom: 0;
  z-index: 11;
  align-items: center;
  justify-content: center;
  display: flex;
  color: #333;
  background-color: #fff;
}

.circle {
  background: green;
  border-radius: 50%;
  color: #fff;
  height: 2.5em;
  position: relative;
  width: 2.5em;

  border: 1px solid transparent;
}

.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}
.locationicon {
  color: #55ff00;
  width: 35px !important;
  height: 35px !important;
}
.closebtn_outer {
  color: #4e4e4e;
  z-index: 111;
  position: absolute;
  top: -30px;
  right: 15px;
  cursor: pointer;
  background: #fff;
  padding: 3px;
  border-radius: 15px 15px 0 0;
}
